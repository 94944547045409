import * as React from "react";
import classNames from "classnames/bind";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { useScroll } from "../../hooks/useScroll";

import { Logo } from "../images/logo";
import styles from "./TopBar.module.css";
const cx = classNames.bind(styles);

export const TopBar = () => {
  const { element, fixed } = useScroll();
  return (
    <header
      ref={element}
      className={cx({
        navigation: true,
        fixed,
      })}
    >
      <div className={styles.content}>
        <div>
          <AnchorLink href={"#video"}>
            <Logo />
          </AnchorLink>
        </div>
        <nav>
          <ul>
            <li>
              <AnchorLink href={"#about"}>O konkursie</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#benefits"}>Dlaczego warto</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#mentors"}>Mentorzy</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#roles"}>Dla kogo</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#recruitment-process"}>Rekrutacja</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#timeline"}>Timeline</AnchorLink>
            </li>
            <li>
              <AnchorLink href={"#contact"}>Kontakt</AnchorLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
