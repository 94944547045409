import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { LogoWithText } from "../images/logoWithText";
import Facebook from "../../images/icons/facebook.svg";
import LinkedIn from "../../images/icons/linkedin.svg";
import Terms from "../../images/terms.pdf";
import styles from "./Footer.module.css";

interface SocialLinkProps {
  Icon: React.ReactElement;
  to: string;
}

export const Footer = () => (
  <footer className={styles.footer}>
    <div>
      <a target={"_blank"} href="https://hltech.com">
        <LogoWithText />
      </a>
    </div>
    <div>
      <ul className={styles.links}>
        <li>
          <AnchorLink href={"#contact"}>Kontakt</AnchorLink>
        </li>
        <li>
          <a
            target={"_blank"}
            href="https://www.hltech.com/kariera/oferty-pracy/"
          >
            Kariera
          </a>
        </li>
        <li>
          <a target={"_blank"} href={Terms}>
            Regulamin
          </a>
        </li>
      </ul>
    </div>
    <div>
      <ul className={styles.socials}>
        <li>
          <p style={{ color: "#fff", margin: 0 }}>Śledź nas</p>
        </li>
        <li>
          <SocialLink
            to={"https://www.facebook.com/HLTechCentre/"}
            Icon={<Facebook />}
          />
        </li>
        <li>
          <SocialLink
            to={"https://www.linkedin.com/company/hltechcentre/"}
            Icon={<LinkedIn />}
          />
        </li>
      </ul>
    </div>
  </footer>
);

export const SocialLink = ({ Icon, to }: SocialLinkProps) => (
  <a target={"_blank"} className={styles.social} href={to}>
    {Icon}
  </a>
);
