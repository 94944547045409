import * as React from "react";

const initialProps: ModalOptions = { name: "", isOpen: false };

export enum ModalType {
  fe = "fe",
  be = "be",
  qa = "qa",
  analyst = "analyst",
}

interface ModalContextProps {
  isModalOpen(name: ModalType): boolean;
  showModal(name: ModalType): void;
  hideModal(name: ModalType): void;
  modal: ModalOptions;
  modalRef: HTMLDivElement | null;
}

export const ModalContext = React.createContext<ModalContextProps>({
  modal: initialProps,
  isModalOpen(): boolean {
    return false;
  },
  hideModal(): void {},
  showModal(): void {},
  modalRef: null,
});

interface ModalOptions {
  name: string;
  isOpen: boolean;
}

export const ModalProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [modal, setModal] = React.useState<ModalOptions>(initialProps);
  const [context, setContext] = React.useState<HTMLDivElement | null>(null);

  const modalRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    modalRef && setContext(modalRef.current);
  }, []);

  const showModal = (name: ModalType) => {
    setModal({ name, isOpen: true });
  };

  const hideModal = (name: ModalType) => {
    setModal({ name, isOpen: false });
  };

  const isModalOpen = (name: ModalType): boolean => {
    return modal.name === name && modal.isOpen;
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          showModal,
          hideModal,
          modalRef: context,
          isModalOpen,
          modal,
        }}
      >
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};
