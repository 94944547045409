import * as React from "react";
import { PropsWithChildren } from "react";

import { Footer } from "../Footer/Footer";
import { TopBar } from "../TopBar/TopBar";
import { ModalProvider } from "../../providers/ModalProvider";

import "../layout.css";

interface LayoutProps {}

export const Container = (props: PropsWithChildren<{}>) => (
  <div className={"container"}>{props.children}</div>
);

export const Layout = ({ children }: PropsWithChildren<LayoutProps>) => {
  return (
    <>
      <TopBar />
      <ModalProvider>
        <main>{children}</main>
      </ModalProvider>
      <Footer />
    </>
  );
};
